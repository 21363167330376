import React from "react";

import ActionButton from "@buttons/actionButton";
import { useAccount } from "@/features/accounts/useAccount";
import { PageLayout } from "@layout/page-layout";
import { useGetMissionsQuery } from "@services/api/apiSlice";
import Loader from "@elements/loader";
import { usePermissions } from "@/features/permissions/usePermissions";
import EntityList from "@/components/lists/entity-list";

/**
 * Represents the Mission Page component.
 * This component displays a list of missions and allows users to create, edit, delete, and duplicate missions.
 */
const MissionsPage: React.FC = () => {
  const { account } = useAccount();

  const { canWrite } = usePermissions();

  const {
    data: missions = [],
    error,
    isLoading,
  } = useGetMissionsQuery({ account: account?.account || "" });

  return (
    <PageLayout>
      <div className="flex  max-w-full p-6 justify-items items-center">
        <h1 className="mb-2 text-2xl mr-auto font-semibold text-gray-900">
          Missions
        </h1>
        {canWrite() && (
          <div className="flex flex-row gap-4">
            <ActionButton to="/missions/new">Create Mission</ActionButton>
          </div>
        )}
      </div>
      <section className="missions-list">
        {isLoading ? (
          <Loader />
        ) : error ? (
          <div>Error loading missions</div>
        ) : (
          <EntityList entities={missions} entityType="missions" />
        )}
      </section>
    </PageLayout>
  );
};

export default MissionsPage;
