import { useUser } from "@features/user/useUser";
import { permissionHelpers, Permissions } from "./permissions";

export function usePermissions() {
  const { user } = useUser();
  const permissions = user?.permissions || [Permissions.NONE];

  return {
    canWriteAdmin: () => permissionHelpers.canWriteAdmin(permissions),
    canReadAdmin: () => permissionHelpers.canReadAdmin(permissions),
    canWrite: () => permissionHelpers.canWrite(permissions),
    canRead: () => permissionHelpers.canRead(permissions),
    canReadAll: () =>
      permissionHelpers.canWriteAdmin(permissions) ||
      permissionHelpers.canReadAdmin(permissions),
    // Raw permissions array
    permissions,
  };
}
