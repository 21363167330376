import { memo } from "react";
//import { useAccount } from "@/features/accounts/useAccount";
//import { Account } from "@/features/accounts/Account";
import { ElementBase } from "@features/common/Common";
import { ExpandableProvider } from "@buttons/expandableButtons";
import { EntityPreview } from "@elements/entityPreview";

interface EntityListProps {
  entities: ElementBase[];
  entityType: string;
}

export const EntityList = memo(
  function EntityList({
    entities,
    entityType,
  }: EntityListProps) {
    //const { setAccount } = useAccount();
    const content = entities.map((entity) => (
      <li
        key={entity?.id || entity?.name}
        className={`flex flex-row text-primary-black bg-white gap-2 mx-2 shadow-[#9E9E9E] border border-gray-300 rounded-2xl p-4 ${entityType === 'accounts' ? 'cursor-pointer' : ''}`}
        /* Want to get 'clickable' effect accounts
        onClick={() => {
          if (entityType === 'accounts') {
            setAccount(entity);
          }
        }}
          */
      >
        <EntityPreview entityType={entityType} entity={entity} />
      </li>
    ));
  
    return (
      <ExpandableProvider>
        <ul className=" flex flex-col gap-4">
          {content}
        </ul>
      </ExpandableProvider>
    );
  }
)

export default EntityList;