import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const SignUpButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = () => {
    loginWithRedirect({authorizationParams: {
      screen_hint: "signup",
    }});
  };

  return (
    <button
      className="flex-1 bg-blue-500 hover:bg-blue-700 text-white uppercase py-4 px-4 rounded-full" 
      onClick={handleSignUp}>
      Create Account
    </button>
  );
};
