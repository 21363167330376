import React, { createContext, useState, ReactNode } from "react";
import { Account, AccountContextType } from "./Account";

export const AccountContext = createContext<AccountContextType | undefined>(
  undefined
);

interface AccountProviderProps {
  children: ReactNode;
}

export const AccountProvider: React.FC<AccountProviderProps> = ({
  children,
}) => {
  const [account, setAccount] = useState<Account | null>(null);

  return (
    <AccountContext.Provider value={{ account, setAccount }}>
      {children}
    </AccountContext.Provider>
  );
};
