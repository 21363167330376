import React from "react";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { RefreshPeriod } from "@features/common/Common";

interface RefreshPeriodSelectorProps {
  value: RefreshPeriod;
  onChange: (value: RefreshPeriod) => void;
}

const RefreshPeriodSelector: React.FC<RefreshPeriodSelectorProps> = ({
  value,
  onChange,
}) => {
  const options: { label: string; value: RefreshPeriod }[] = [
    { label: "None", value: "all-time" },
    { label: "Day", value: "daily" },
    { label: "Week", value: "weekly" },
    { label: "Month", value: "monthly" },
  ];

  return (
    <div className="flex flex-col justify-end w-full">
      <Label htmlFor="refresh-period" className="text-sm mb-1.5">
        Refresh Period
      </Label>
      <div className="h-9 flex items-center">
        <RadioGroup
          id="refresh-period"
          value={value}
          onValueChange={onChange}
          className="flex items-center gap-6 w-full"
        >
          {options.map((option) => (
            <div
              key={option.value}
              className="flex items-center gap-2 min-w-[80px]"
            >
              <RadioGroupItem
                value={option.value}
                id={option.value}
                className="h-5 w-5 border border-primary-500 text-primary-500 relative
                  before:content-[''] before:absolute before:inset-[2px] before:rounded-full before:hidden
                  after:content-[''] after:absolute after:inset-[3px] after:rounded-full after:hidden
                  data-[state=checked]:after:block data-[state=checked]:after:bg-primary-500
                  data-[state=checked]:before:block data-[state=checked]:before:border-2 data-[state=checked]:before:border-white"
              />
              <Label
                htmlFor={option.value}
                className="cursor-pointer select-none text-sm whitespace-nowrap"
              >
                {option.label}
              </Label>
            </div>
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};

export default RefreshPeriodSelector;
