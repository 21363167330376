import React, { useState, useEffect } from "react";

import { useGetAccountsQuery } from "@/services/api/apiSlice";

import { ListPicker, ListItem } from "@pickers/listPicker";

import { Account } from "@/features/accounts/Account";

import { useAccount } from "@/features/accounts/useAccount";

import ProfileIcon from "@/assets/profile.svg";
import { useUser } from "@/features/user/useUser";

const AccountPicker: React.FC = () => {
  const { data: accounts = [], isLoading } = useGetAccountsQuery();
  const [selection, setSelection] = useState<ListItem | null>(null);
  const { account, setAccount } = useAccount();
  const { user } = useUser();

  useEffect(() => {
    if (!isLoading && accounts.length > 0 && account) {
      console.log("User accounts:", user?.accounts);
      const selectedAccount = accounts.find((acc) => acc === account);
      setSelection({
        id: selectedAccount?.id ?? "",
        name: selectedAccount?.name ?? "",
        disabled: !selectedAccount?.isAvailable,
      });
    }
  }, [isLoading, account, accounts]);

  const handleSelection = (value: ListItem) => {
    setSelection(value);
    const selAcc = accounts.find((acc) => acc.account === value.id) ?? null;
    setAccount(selAcc);
  };

  const populateItems = (items: Account[], selection: ListItem | null) => {
    const filteredItems = items
    // Need to filter out accounts that are not available to the user.
    //.filter((item) => user.accounts.includes(item.account))
      .filter((item) => item.account !== selection?.id)
      .filter((item) => item.isAvailable)
      .map((item) => {
      return {
        id: item.account,
        name: item.name,
        disabled: !item.isAvailable,
      };
      });
    return filteredItems;
  };

  return (
    <div className="rounded-full flex flex-row items-center justify-between bg-neutral-100 text-black shadow border-solid border-2 px-2">
      <ListPicker
        icon={ProfileIcon}
        currentValue={account?.account}
        items={populateItems(accounts, selection)}
        listType="Account"
        placeholder="Account"
        className="min-w-48 m-0 p-0"
        onSelect={handleSelection}
      />
    </div>
  );
};

export default AccountPicker;
