import React from "react";
import DatePicker from "./datepicker";

interface TimeRange {
  from: string;
  to: string;
}

interface DateRangePickerProps {
  onChange: (range: TimeRange) => void;
  value?: TimeRange;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  onChange,
  value,
}) => {
  const handleStartDateChange = (date: Date | undefined) => {
    onChange({
      from: date ? date.toISOString() : "",
      to: value?.to || "",
    });
  };

  const handleEndDateChange = (date: Date | undefined) => {
    onChange({
      from: value?.from || "",
      to: date ? date.toISOString() : "",
    });
  };

  return (
    <div className="grid grid-cols-2 gap-4 w-full col-span-2">
      <DatePicker
        label="Start Date"
        onChange={handleStartDateChange}
        value={value?.from}
      />
      <DatePicker
        label="End Date"
        onChange={handleEndDateChange}
        value={value?.to}
      />
    </div>
  );
};

export default DateRangePicker;
