import { ElementBase, defaultElementBase } from "@features/common/Common";

export interface Analytics extends ElementBase {
  players: number;
  activePlayers: number;
  missions: number;
  startedMissions: number;
  points: number;
  credits: number;
  achievements: number;
  unlockedAchievements: number;
  levelUps: number;
  prizeDraws: number;
  mysteryBoxes: number;
  prizes: number;
  apiCalls: number;
  surveys: number;
  quizzes: number;
  // limited + unlimited
  streaksStarted: number;
  // limited only
  streaksCompleted: number;
  debug: string;
}

export const defaultAnalytics: Analytics = {
  ...defaultElementBase,
  players: 0,
  activePlayers: 0,
  missions: 0,
  startedMissions: 0,
  points: 0,
  credits: 0,
  achievements: 0,
  unlockedAchievements: 0,
  levelUps: 0,
  prizeDraws: 0,
  mysteryBoxes: 0,
  prizes: 0,
  apiCalls: 0,
  surveys: 0,
  quizzes: 0,
  streaksStarted: 0,
  streaksCompleted: 0,
  debug: "this needs to be checked",
};