export const ADMIN_READ = "admin:read";
export const ADMIN_WRITE = "admin:write";
export const ACCOUNT_READ = "account:read";
export const ACCOUNT_WRITE = "account:write";
export const NONE = "none";

export const Permissions = {
  ADMIN: {
    READ: "admin:read",
    WRITE: "admin:write",
  },
  ACCOUNT: {
    READ: "account:read",
    WRITE: "account:write",
  },
  NONE: "none",
} as const;

export const permissionHelpers = {
  // Admin permissions
  canWriteAdmin: (permissions: string[]): boolean => {
    return permissions.includes(Permissions.ADMIN.WRITE);
  },

  canReadAdmin: (permissions: string[]): boolean => {
    return (
      permissions.includes(Permissions.ADMIN.READ) ||
      permissions.includes(Permissions.ADMIN.WRITE)
    );
  },

  // Account permissions
  canWrite: (permissions: string[]): boolean => {
    return (
      permissions.includes(Permissions.ACCOUNT.WRITE) ||
      permissions.includes(Permissions.ADMIN.WRITE)
    );
  },

  canRead: (permissions: string[]): boolean => {
    return (
      permissions.includes(Permissions.ACCOUNT.READ) ||
      permissions.includes(Permissions.ACCOUNT.WRITE) ||
      permissions.includes(Permissions.ADMIN.READ) ||
      permissions.includes(Permissions.ADMIN.WRITE)
    );
  },
};
