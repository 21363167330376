import { useState, useEffect } from "react";
import { ListPicker, ListItem } from "@pickers/listPicker";
import ItemPicker from "@pickers/itemPicker";
import MissionPicker from "@/assets/icons/MissionPicker.png";
import AchievementPicker from "@/assets/icons/AchievementPicker.png";

interface BaseEntity {
  id: string;
  name: string;
  imgUrl: string;
}

interface EntitySelectorProps<T extends BaseEntity> {
  entities: T[];
  selectedIds?: string[];
  isLoading: boolean;
  title: string;
  listType: string;
  className?: string;
  singleSelect?: boolean;
  onSelectionChange: (ids: string[]) => void;
}

function EntitySelector<T extends BaseEntity>({
  entities,
  selectedIds = [],
  isLoading,
  title,
  listType,
  className,
  singleSelect = false,
  onSelectionChange,
}: EntitySelectorProps<T>) {
  const [selection, setSelection] = useState<T[]>([]);
  const [listItems, setListItems] = useState<ListItem[]>([]);

  // Update selection based on selectedIds
  useEffect(() => {
    if (!isLoading && entities.length > 0 && selectedIds.length > 0) {
      const filteredEntities = entities.filter((item) =>
        selectedIds.includes(item.id)
      );
      setSelection((prevSelection) =>
        JSON.stringify(prevSelection) !== JSON.stringify(filteredEntities)
          ? filteredEntities
          : prevSelection
      );
    }
  }, [isLoading, entities, selectedIds]);

  // Update list items
  useEffect(() => {
    if (!isLoading) {
      const entityList = entities.map((item) => ({
        id: item.id,
        name: item.name,
        disabled: selection.some((item2) => item2.id === item.id),
      }));
      setListItems(entityList);
    }
  }, [isLoading, entities, selection]);

  const handleSelect = (item: ListItem) => {
    if (isLoading) return;

    const selectedEntity = entities.find((e) => e.id === item.id);
    if (!selectedEntity) return;

    setSelection((prevSelected) => {
      let newSelection: T[];

      if (singleSelect) {
        // For single select, just replace the selection
        newSelection = [selectedEntity];
      } else {
        // For multi-select, toggle the selection
        const isAlreadySelected = prevSelected.some((e) => e.id === item.id);
        newSelection = isAlreadySelected
          ? prevSelected.filter((e) => e.id !== item.id)
          : [...prevSelected, selectedEntity];
      }

      // Notify parent of the change
      onSelectionChange(newSelection.map((e) => e.id));
      return newSelection;
    });
  };

  const handleDelete = (id: string) => {
    const updatedSelection = selection.filter((item) => item.id !== id);
    setSelection(updatedSelection);
    onSelectionChange(updatedSelection.map((e) => e.id));
  };
  // currentValue={selection.at(-1)?.id}
  return (
    <>
      <div className="col-start-1">
        <ListPicker
          icon={listType === "Mission" ? MissionPicker : AchievementPicker}
          items={listItems}
          className={className}
          listType={listType}
          onSelect={handleSelect}
          mode="select"
        />
      </div>
      <div className="col-start-2 space-y-4">
        {selection.map((item: T) => (
          <ItemPicker
            key={item.id}
            id={item.id}
            imgUrl={item.imgUrl}
            title={`${title}:`}
            name={item.name}
            onDelete={handleDelete}
          />
        ))}
      </div>
    </>
  );
}

export default EntitySelector;
