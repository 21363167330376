export const PageLoader: React.FC = () => {
  const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg"; // Replace with a Christmas-themed image URL

  return (
    <div style={styles.container}>
      <div style={styles.snowflakes}>
        {Array.from({ length: 150 }).map((_, index) => (
          <div
            key={index}
            style={{
              ...styles.snowflake,
              left: `${Math.random() * 100}%`,
              opacity: `${Math.random() + 0.1}`,
              animationDelay: `${Math.random() * 5}s`,
            }}
          >
            ❄
          </div>
        ))}
      </div>
      <img src={loadingImg} alt="Loading..." style={styles.image} />
      <h1 style={styles.text}>Christmas is coming!</h1>
      <p style={styles.text}>Ho Ho Ho!</p>
    </div>
  );
};

import { CSSProperties } from "react";

const styles: { [key: string]: CSSProperties } = {
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
    backgroundColor: "#f0f8ff", // Light blue background
    color: "#d32f2f", // Red text color
    fontFamily: "Arial, sans-serif",
  },
  snowflakes: {
    position: "absolute",
    top: -20,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
  },
  snowflake: {
    position: "absolute",
    top: "-10px",
    fontSize: "24px",
    animation: `fall ${Math.random() * 3 + 2}s linear infinite`,
    left: `${Math.random() * 100}%`,
  },
  image: {
    width: "150px",
    marginBottom: "20px",
  },
  text: {
    fontSize: "24px",
    fontWeight: "bold",
  },
};

// Add this to your CSS file or use a styled-components approach
/*
const fallAnimation = `
@keyframes fall {
  to {
    transform: translateY(100vh);
  }
}
`;

const styleSheet = document.styleSheets[0];
styleSheet.insertRule(fallAnimation, styleSheet.cssRules.length);
*/

export default PageLoader;
