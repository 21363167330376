import { useState, useEffect } from "react";
import { ListPicker, ListItem } from "@pickers/listPicker";
import ItemPicker from "@pickers/itemPicker";

interface BaseEntity {
  id: string;
  name: string;
  imgUrl: string;
}
interface SingleEntitySelectorProps<T extends BaseEntity> {
  entities: T[];
  selectedEntity?: string;
  isLoading: boolean;
  className?: string;
  entityType: string;
  icon: string;
  onChange: (id: string | undefined) => void;
}

const SingleEntitySelector = <T extends BaseEntity>({
  entities,
  selectedEntity,
  isLoading,
  className,
  entityType,
  icon,
  onChange,
}: SingleEntitySelectorProps<T>) => {
  const [listItems, setListItems] = useState<ListItem[]>([]);
  const [selectedEntitySet, setSelectedLevelEntity] = useState<T | undefined>();

  // Update list items
  useEffect(() => {
    if (!isLoading) {
      const list = entities.map((item) => ({
        id: item.id,
        name: item.name,
        disabled: selectedEntity === item.id,
      }));
      setListItems(list);
    }
  }, [isLoading, entities, selectedEntity]);

  // Update selected entity
  useEffect(() => {
    if (!isLoading && selectedEntity) {
      const levelEntity = entities.find((item) => item.id === selectedEntity);
      setSelectedLevelEntity(levelEntity);
    } else {
      setSelectedLevelEntity(undefined);
    }
  }, [isLoading, entities, selectedEntity]);

  const handleSelect = (item: ListItem) => {
    if (isLoading) return;

    const selEntity = entities.find((e) => e.id === item.id);
    console.log(selEntity);
    if (!selEntity) return;

    onChange(selEntity.id);
    setSelectedLevelEntity(selEntity);
  };

  const handleDelete = () => {
    onChange(undefined);
    setSelectedLevelEntity(undefined);
  };

  return (
    <>
      <div className="col-start-1">
        <ListPicker
          icon={icon}
          items={listItems}
          currentValue={selectedEntity}
          className={className}
          listType={entityType}
          onSelect={handleSelect}
          mode="select"
        />
      </div>
      <div className="col-start-2 space-y-4">
        {selectedEntitySet && (
          <ItemPicker
            key={selectedEntitySet.id}
            id={selectedEntitySet.id}
            imgUrl={selectedEntitySet.imgUrl}
            title={`${entityType} Name`}
            name={selectedEntitySet.name}
            onDelete={handleDelete}
          />
        )}
      </div>
    </>
  );
};

export default SingleEntitySelector;
