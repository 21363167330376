import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const ADMIN_READ = "admin:read";
const ADMIN_WRITE = "admin:write";
const ACCOUNT_READ = "account:read";
const ACCOUNT_WRITE = "account:write";

export const ProfilePage: React.FC = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [role, setRole] = useState<string>("");
  const [accounts, setAccounts] = useState<string[]>([]);

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();
      const jsonObj = parseJwt(accessToken);
      const appMetadata = jsonObj["https://api.gamelayer.co/app_metadata"];
      setAccounts(
        appMetadata && appMetadata.accounts ? appMetadata.accounts : []
      );
      if (
        jsonObj.permissions.includes(ADMIN_READ) &&
        jsonObj.permissions.includes(ADMIN_WRITE)
      ) {
        setRole("service admin");
      } else if (jsonObj.permissions.includes(ACCOUNT_WRITE)) {
        setRole("account admin");
      } else if (jsonObj.permissions.includes(ACCOUNT_READ)) {
        setRole("account reader");
      } else {
        setRole("no role");
      }

      if (!isMounted) {
        return;
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  if (!user) {
    return null;
  }

  return (
    <div>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Profile Page
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>
              You can use the <strong>ID Token</strong> to get the profile
              information of an authenticated user.
            </span>
            <span>
              <strong>Only authenticated users can access this page.</strong>
            </span>
          </p>
          <div className="profile-grid">
            <div className="profile__header">
              <img
                src={user.picture}
                alt="Profile"
                className="profile__avatar"
              />
              <div className="profile__headline">
                <h2 className="profile__title">{user.name}</h2>
                <span className="profile__description">{user.email}</span>
              </div>
            </div>
            <div className="profile__details">
              {JSON.stringify(user, null, 2)}
            </div>

            <div className="profile__details">{role}</div>
            <div className="profile__details">{accounts}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
