import React from 'react';
import { Link } from 'react-router-dom';

type ActionButtonProps = {
  onClick?: () => void;
  label?: string;
  to?: string;
  children: React.ReactNode;
};

const ActionButton: React.FC<ActionButtonProps> = ({ label, onClick, to, children }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (to) {
    return (
      <Link
        to={to}
        onClick={handleClick}
        title={label}
        className="rounded-full px-6 py-2 bg-primary-500 text-primary-foreground shadow hover:bg-primary-200 hover:text-primary-500 border-primary-500 border-solid border-2">
        {children}
      </Link>
    );
  }

  return (
    <button
      onClick={handleClick}
      title={label}
      className="rounded-full px-6 py-2 bg-primary-500 text-primary-foreground shadow hover:bg-primary-200 hover:text-primary-500 border-primary-500 border-solid border-2">
      { label }
      {children}
    </button>
  );
};

export default ActionButton;
