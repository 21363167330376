import React, { useState, useEffect } from "react";
import { ListPicker, ListItem } from "@pickers/listPicker";
import ItemPicker from "@pickers/itemPicker";
import LevelPicker from "@/assets/icons/LevelPicker.png";
import { Level } from "@/features/levels/Level";
import { RequirementLevel } from "@/features/common/Common";

interface LevelSelectorProps {
  levels: Level[];
  selectedLevel?: RequirementLevel;
  isLoading: boolean;
  className?: string;
  onChange: (level: RequirementLevel | undefined) => void;
}

const LevelSelector: React.FC<LevelSelectorProps> = ({
  levels,
  selectedLevel,
  isLoading,
  className,
  onChange,
}) => {
  const [listItems, setListItems] = useState<ListItem[]>([]);
  const [selectedLevelEntity, setSelectedLevelEntity] = useState<
    Level | undefined
  >();

  // Update list items
  useEffect(() => {
    if (!isLoading) {
      const levelList = levels.map((item) => ({
        id: item.id,
        name: item.name,
        disabled: selectedLevel?.id === item.id,
      }));
      setListItems(levelList);
    }
  }, [isLoading, levels, selectedLevel]);

  // Update selected level entity
  useEffect(() => {
    if (!isLoading && selectedLevel) {
      const levelEntity = levels.find((level) => level.id === selectedLevel.id);
      setSelectedLevelEntity(levelEntity);
    } else {
      setSelectedLevelEntity(undefined);
    }
  }, [isLoading, levels, selectedLevel]);

  const handleSelect = (item: ListItem) => {
    if (isLoading) return;

    const levelEntity = levels.find((e) => e.id === item.id);
    if (!levelEntity) return;

    // If clicking on already selected item, clear the selection
    if (selectedLevel?.id === item.id) {
      onChange(undefined);
      setSelectedLevelEntity(undefined);
    } else {
      // Create new RequirementLevel from the selected Level
      onChange({
        id: levelEntity.id,
        ordinal: levelEntity.ordinal,
      });
      setSelectedLevelEntity(levelEntity);
    }
  };

  const handleDelete = () => {
    onChange(undefined);
    setSelectedLevelEntity(undefined);
  };

  return (
    <>
      <div className="col-start-1">
        <ListPicker
          icon={LevelPicker}
          items={listItems}
          currentValue={selectedLevel?.id}
          className={className}
          listType="Level"
          onSelect={handleSelect}
          mode="select"
        />
      </div>
      <div className="col-start-2 space-y-4">
        {selectedLevelEntity && (
          <ItemPicker
            key={selectedLevelEntity.id}
            id={selectedLevelEntity.id}
            imgUrl={selectedLevelEntity.imgUrl}
            title="Level Name"
            name={selectedLevelEntity.name}
            onDelete={handleDelete}
          />
        )}
      </div>
    </>
  );
};

export default LevelSelector;
