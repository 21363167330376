import React, { useEffect, useMemo } from "react";
import { Account } from "./Account";
import { PageLayout } from "@layout/page-layout";
import { useGetAccountsQuery } from "@services/api/apiSlice";
import Loader from "@elements/loader";
import { useAccount } from "./useAccount";
import { useUser } from "../user/useUser";
import EntityList from "@/components/lists/entity-list";

const AccountsPage: React.FC = () => {
  const { account } = useAccount();
  const [availableAccounts, setAvailableAccounts] = React.useState<Account[]>(
    []
  );
  const { data: accounts = [], error, isLoading } = useGetAccountsQuery();
  const { user } = useUser();

  // Robert: this is the strucure for loading data like lists
  let content: React.ReactNode;

  // Limit accounts to only those, that available for user.
  useEffect(() => {
    if (!isLoading && accounts.length) {
      const availableAccounts = accounts
        .filter((acc) => acc.isAvailable)
        /* 
          This needs to be fixed to only show accounts that the user has access to.
          .filter(
            (acc) => !user?.accounts || user?.accounts.includes(acc.account)
          );
         */
      setAvailableAccounts(availableAccounts);
    }
  }, [accounts, isLoading, user, account]);

  // Robert: this is the way to sort elements, like by name
  const sortedAccounts = useMemo(() => {
    const sortedAccounts = availableAccounts.slice();
    // Sort accounts by name
    sortedAccounts.sort((a, b) => b.name.localeCompare(a.name));
    return sortedAccounts;
  }, [availableAccounts]);

  if (isLoading) {
    <Loader />;
  } else if (error) {
    // Robert: maybe using a toast here

    content = <div>Error: {error.toString()}</div>;
  } else {
    content = (
      <PageLayout>
        <div className="flex max-w-full  p-6 justify-items items-center">
          <h1 className="mb-2 text-2xl mr-auto font-semibold text-gray-900">
            Accounts
          </h1>
        </div>
        <section className="accounts-list">
          <EntityList entities={sortedAccounts} entityType="accounts" />
        </section>
      </PageLayout>
    );
  }

  return <>{content}</>;
};

export default AccountsPage;
