import React, { useState } from 'react';

const FieldHelp: React.FC<{ helpText?: string }> = ({ helpText = "Fill this field please." }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="relative"
    >
      <span 
        className="flex border-primary-500 shadow-md items-center justify-center bg-primary-200 p-1 w-4 h-4 rounded-full text-xs"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        >
          ?
      </span>
      {isHovered && <div className="absolute shadow-md border-primary-500 bg-primary-200 opacity-85 py-2 px-4 min-w-40 rounded-2xl top-6 right-1 z-10">{helpText}</div>}
    </div>
  );
};

export default FieldHelp;