import {
  TimeRange,
  RefreshPeriod,
  Timing,
  defaultTimeRange,
  defaultElementBase,
  SubObjectives,
  Requirements,
  defaultRequirements,
  Language,
  ElementBase,
  Rewards,
  defaultRewards,
} from "@features/common/Common";

export interface MissionObjectives {
  events: SubObjectives[];
  missions: SubObjectives[];
  quizzId: string;
  surveyId: string;
}

export interface Mission extends ElementBase {
  active: TimeRange;
  period: RefreshPeriod;
  timing: Timing;
  timeToComplete: number;
  timeToRestart: number;
  priority: number;
  limitCount: boolean;
  countLimit: number;
  objectives: MissionObjectives;
  requirement: Requirements;
  reward: Rewards;
  languages: Language[];
}

export const defaultMission: Mission = {
  ...defaultElementBase,
  active: defaultTimeRange,
  period: "daily",
  timing: { maxSecondsToComplete: 0, minSecondsToRestart: 0 },
  timeToComplete: 0,
  timeToRestart: 0,
  priority: 0,
  limitCount: true,
  countLimit: 1,
  objectives: { events: [], missions: [], quizzId: "", surveyId: "" },
  requirement: defaultRequirements,
  languages: [],
  reward: defaultRewards,
};
