import React, { useState, useEffect } from "react";
import { useGetMissionsQuery } from "@services/api/apiSlice";
import { useAccount } from "@/features/accounts/useAccount";
import { StreakObjectives } from "@/features/streaks/Streak";
import MissionPickerIcon from "@/assets/icons/MissionPicker.png";
import SingleEntitySelector from "@/components/pickers/SingleEntitySelector";

interface StreakObjectivesPickerProps {
  objectives: StreakObjectives;
  className?: string;
  onChange: (value: StreakObjectives) => void;
}

const StreakObjectivesPicker: React.FC<StreakObjectivesPickerProps> = ({
  objectives,
  className,
  onChange,
}) => {
  const { account } = useAccount();
  const accountId = account?.account || "";

  // Queries
  const { data: missions = [], isLoading: isLoadingMissions } =
    useGetMissionsQuery({
      account: accountId,
    });

  const [selectedMission, setSelectedMission] = useState<string>("");

  // Initialize selections from objectives
  useEffect(() => {
    if (!isLoadingMissions && objectives && objectives.length >= 1) {
      setSelectedMission(objectives[0]);
    }
  }, [objectives, isLoadingMissions]);

  const handleMissionChange = (id: string | undefined) => {
    setSelectedMission(id || "");
    onChange(id ? [id] : []);
  };

  if (isLoadingMissions) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2 className="text-1xl font-bold mb-4">Objectives</h2>
      <div className="grid grid-cols-2 gap-4">
        <SingleEntitySelector
          entities={missions}
          selectedEntity={selectedMission}
          isLoading={isLoadingMissions}
          className={className}
          entityType="Mission"
          icon={MissionPickerIcon}
          onChange={handleMissionChange}
        />
      </div>
    </div>
  );
};

export default StreakObjectivesPicker;
