import {
  TimeRange,
  RefreshPeriod,
  defaultTimeRange,
  defaultElementBase,
  Language,
  ElementBase,
  Rewards,
  defaultRewards,
} from "@features/common/Common";

export type StreakObjectives = string[];

export interface Streak extends ElementBase {
  limitCount: boolean;
  countLimit: number;
  priority: number;
  active: TimeRange;
  period: RefreshPeriod;
  objectives: StreakObjectives;
  reward: Rewards;
  languages: Language[];
}

export const defaultStreak: Streak = {
  ...defaultElementBase,
  limitCount: true,
  countLimit: 1,
  priority: 0,
  active: defaultTimeRange,
  period: "monthly",
  objectives: [],
  reward: defaultRewards,
  languages: [],
};
