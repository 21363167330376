import React from "react";

import ActionButton from "@buttons/actionButton";
import { useAccount } from "@/features/accounts/useAccount";
import { PageLayout } from "@layout/page-layout";
import { useGetStreaksQuery } from "@services/api/apiSlice";
import Loader from "@elements/loader";
import { usePermissions } from "@/features/permissions/usePermissions";
import EntityList from "@/components/lists/entity-list";

/**
 * Represents the Streak Page component.
 * This component displays a list of streaks and allows users to create, edit, delete, and duplicate streaks.
 */
const StreaksPage: React.FC = () => {
  const { account } = useAccount();

  const { canWrite } = usePermissions();

  const {
    data: streaks = [],
    error,
    isLoading,
  } = useGetStreaksQuery({ account: account?.account || "" });

  return (
    <PageLayout>
      <div className="flex  max-w-full p-6 justify-items items-center">
        <h1 className="mb-2 text-2xl mr-auto font-semibold text-gray-900">
          Streaks
        </h1>
        {canWrite() && (
          <div className="flex flex-row gap-4">
            <ActionButton to="/streaks/new">Create Streak</ActionButton>
          </div>
        )}
      </div>
      <section className="streaks-list">
        {isLoading ? (
          <Loader />
        ) : error ? (
          <div>Error loading streaks</div>
        ) : (
          <EntityList entities={streaks} entityType="streaks" />
        )}
      </section>
    </PageLayout>
  );
};

export default StreaksPage;
