import React, { useEffect, useMemo } from "react";

import ActionButton from "@buttons/actionButton";
import { useAccount } from "@features/accounts/useAccount";
import { useGetLevelsQuery } from "@services/api/apiSlice";
import { usePermissions } from "@/features/permissions/usePermissions";
import Loader from "@elements/loader";
import EntityList from "@components/lists/entity-list";
import { PageLayout } from "@layout/page-layout";

const LevelsPage: React.FC = () => {
  const { account } = useAccount();
  const { canWrite } = usePermissions();
  const {
    data: levels = [],
    error,
    isLoading,
  } = useGetLevelsQuery({ account: account?.account || "" });

  const sortedLevels = useMemo(() => {
    const sortedLevels = levels.slice();
    // Sort levels by name
    sortedLevels.sort((a, b) => b.name.localeCompare(a.name));
    return sortedLevels;
  }, [levels]);

  useEffect(() => {}, [sortedLevels, isLoading, error]);

  return (
    <PageLayout>
      <div className="flex  max-w-full p-6 justify-items items-center">
        <h1 className="mb-2 text-2xl mr-auto font-semibold text-gray-900">
          Levels
        </h1>
        {canWrite() && (
          <ActionButton label="Create Level" to="/levels/create">
            Create level
          </ActionButton>
        )}
      </div>
      <section className="levels-list">
        {isLoading ? (
          <Loader />
        ) : error ? (
          <div>Error loading levels</div>
        ) : (
          <EntityList entities={levels} entityType="levels" />
        )}
      </section>
    </PageLayout>
  );
};

export default LevelsPage;
