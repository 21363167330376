import {
  Requirements,
  Language,
  ElementBase,
  Rewards,
  defaultElementBase,
  defaultRequirements,
  defaultRewards,
} from "@features/common/Common";

export interface Achievement extends ElementBase {
  steps: number;
  requirement: Requirements;
  reward: Rewards;
  languages: Language[];
}

export const defaultAchievement: Achievement = {
  ...defaultElementBase,
  steps: 1,
  requirement: defaultRequirements,
  languages: [],
  reward: defaultRewards,
};
