import { useMemo } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export type ListItem = {
  id: string;
  name: string;
  disabled: boolean;
};

type SelectionMode = "mark" | "select";

interface ListPickerProps {
  currentValue?: string;
  items: ListItem[];
  icon?: string;
  listType: string;
  className?: string;
  placeholder?: string;
  mode?: SelectionMode;
  onSelect: (item: ListItem) => void;
}

export function ListPicker({
  currentValue,
  placeholder,
  items,
  icon,
  onSelect,
  className,
  listType,
  mode = "mark",
}: ListPickerProps) {
  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => a.name.localeCompare(b.name));
  }, [items]);

  const currentItem =
    mode === "mark"
      ? sortedItems.find((item) => item.id === currentValue)
      : undefined;

  const placeholderContent = (
    <span className="flex gap-2 text-xs md:text-base items-center justify-center">
      {icon && <img src={icon} className="w-6 h-6 p-1" />}
      {mode === "mark"
        ? currentItem?.name || placeholder || `Select ${listType}`
        : placeholder || `Select ${listType}`}
    </span>
  );

  const handleSelect = (value: string) => {
    const item = sortedItems.find((i) => i.id === value);
    if (item) {
      onSelect(item);
    }
  };

  return (
    <div className={className}>
      {sortedItems.length >= 0 ? (
        <Select
          onValueChange={handleSelect}
          value={mode === "mark" ? currentValue : ""}
        >
          <SelectTrigger>
            <SelectValue placeholder={placeholderContent}>
              {placeholderContent}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {sortedItems.map((item) => (
              <SelectItem
                key={item.id}
                value={item.id}
                disabled={item.disabled}
              >
                {item.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      ) : (
        <div></div>
      )}
    </div>
  );
}
