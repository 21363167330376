import React, { useState, createContext, useContext } from 'react';
import { Link } from 'react-router-dom';
import moreCircle from '../../assets/moreCircle.svg';

// Create a context to manage the expanded state globally
const ExpandableContext = createContext<{
  expandedId: string | null;
  setExpandedId: (id: string | null) => void;
}>({
  expandedId: null,
  setExpandedId: () => {},
});

// Create a provider component to wrap your app or the relevant section
export const ExpandableProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [expandedId, setExpandedId] = useState<string | null>(null);

  return (
    <ExpandableContext.Provider value={{ expandedId, setExpandedId }}>
      {children}
    </ExpandableContext.Provider>
  );
};

type ExpandableButtonsProps = {
  id: string; // Add a unique ID to each ExpandableButtons instance
  links: { label: string; url: string }[];
};

export const ExpandableButtons: React.FC<ExpandableButtonsProps> = ({ id, links }) => {
  const { expandedId, setExpandedId } = useContext(ExpandableContext);

  const handleClick = () => {
    // Toggle the expanded state for the clicked button
    setExpandedId(expandedId === id ? null : id);
  };

  return (
    <div className="w-8 h-8">
      <button onClick={handleClick}>
        <img className="w-6 h-6" src={moreCircle} alt="" />
      </button>
      {expandedId === id && (
        <div className="absolute flex -ml-14 flex-col p-2 bg-white border border-gray-300 rounded-2xl shadow-md">
          {links.map((link) => (
            <Link key={link.url} to={link.url}>
              {link.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};