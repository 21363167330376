import {
  ElementBase,
  Language,
  Rewards,
  defaultRewards,
  defaultElementBase,
} from "@features/common/Common";

export interface LevelObjectives {
  points: number;
  credits: number;
  achievements: string[];
}

export interface Level extends ElementBase {
  ordinal: number;
  objectives: LevelObjectives;
  reward: Rewards;
  languages: Language[];
}

export const defaultLevel: Level = {
  ...defaultElementBase,
  ordinal: 1,
  objectives: { points: 0, credits: 0, achievements: [] },
  reward: defaultRewards,
  languages: [],
};
