// BaseEntityForm.tsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "@features/accounts/useAccount";
import { PageLayout } from "@layout/page-layout";
import { Link } from "@components/ui/link";
import { Label } from "@components/ui/label";
import { Button } from "@components/ui/button";
import ImagePicker from "@pickers/imagePicker";
import { useUploadImageMutation } from "@services/api/apiSlice";
import {
  NameInput,
  DescriptionInput,
  IdInput,
  IsAvailableInput,
} from "@fields/Fields";
import { ElementBase } from "@features/common/Common";

interface BaseEntityFormProps<T extends ElementBase> {
  entityName: string;
  entityPath: string;
  entity: T;
  isNew: boolean;
  defaultEntity: T;
  descriptionPlaceholder?: string;
  addEntity: (entity: T) => Promise<T>;
  updateEntity: (entity: T) => Promise<T>;
  renderAdditionalFields: (props: {
    entity: T;
    updateField: <K extends keyof T>(field: K, value: T[K]) => void;
    errors: Partial<Record<keyof T, string>>;
  }) => React.ReactNode;
  additionalValidation?: (
    entity: T,
    errors: Partial<Record<keyof T, string>>
  ) => void;
}

export function BaseEntityForm<T extends ElementBase>({
  entityName,
  entityPath,
  entity: initialEntity,
  isNew,
  defaultEntity,
  descriptionPlaceholder = "Now give it a meaningful description [if desired] for better content management :)",
  addEntity,
  updateEntity,
  renderAdditionalFields,
  additionalValidation,
}: BaseEntityFormProps<T>) {
  const navigate = useNavigate();
  const { account } = useAccount();
  const [uploadImage] = useUploadImageMutation();

  const [entity, setEntity] = useState<T>(defaultEntity);
  const [errors, setErrors] = useState<Partial<Record<keyof T, string>>>({});
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (account) {
      setEntity((prev) => ({ ...prev, account: account.account }));
    }
  }, [account]);

  useEffect(() => {
    setEntity(initialEntity);
  }, [initialEntity]);

  const updateField = <K extends keyof T>(field: K, value: T[K]) => {
    setEntity((prev) => ({ ...prev, [field]: value }));
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: undefined }));
    }
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof T, string>> = {};

    if (!entity.id.trim()) newErrors.id = "Id is required";
    if (!entity.name.trim()) newErrors.name = "Name is required";

    if (additionalValidation) {
      additionalValidation(entity, newErrors);
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const saveImage = async (
    file: File | null,
    currentImgUrl: string
  ): Promise<string> => {
    if (!file || !account?.account) return currentImgUrl;

    const formData = new FormData();
    formData.append("image", file);

    try {
      const result = await uploadImage({
        formData,
        account: account.account,
      }).unwrap();
      return result.path;
    } catch (error) {
      console.error("Failed to upload image:", error);
      return currentImgUrl;
    }
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const imagePath = await saveImage(file, entity.imgUrl);
      console.log(`imagePath: ${imagePath}`);
      const entityWithImage = { ...entity, imgUrl: imagePath };

      if (isNew) {
        await addEntity(entityWithImage);
      } else {
        await updateEntity(entityWithImage);
      }

      navigate(`/${entityPath}`);
    } catch (err) {
      console.error(`Failed to save ${entityName}:`, err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImagePicked = (file: File) => {
    if (file) {
      setFile(file);
    }
  };

  return (
    <PageLayout>
      <div>
        <Link to={`/${entityPath}`} variant="withIcon">
          Back
        </Link>
        <form onSubmit={handleSave} className="mx-4">
          <div className="p-5 bg-neutral-100 rounded-3xl">
            <Label className="text-2xl font-bold my-6">
              {entity.name ? entity.name : `Create new ${entityName}`}
            </Label>

            <div className="grid grid-cols-2 mt-4 gap-4">
              <div className="col-span-1">
                <IsAvailableInput
                  value={entity.isAvailable}
                  onChange={(value) => updateField("isAvailable", value)}
                />
              </div>
              <div className="col-span-1" />

              <div className="col-span-1">
                <NameInput
                  entity_name={entityName}
                  value={entity.name}
                  required
                  onChange={(value) => updateField("name", value)}
                  placeholder={`Your ${entityName} needs a name`}
                  error={errors.name}
                />
              </div>

              <div className="col-span-1">
                <IdInput
                  entity_name={entityName}
                  value={entity.id}
                  required
                  onChange={(value) => updateField("id", value)}
                  placeholder={`And a unique ID`}
                  disabled={!isNew}
                  error={errors.id}
                />
              </div>

              <div className="col-span-1">
                <DescriptionInput
                  value={entity.description}
                  onChange={(value) => updateField("description", value)}
                  placeholder={descriptionPlaceholder}
                />
              </div>

              <div className="col-span-1">
                <ImagePicker
                  path={entity.imgUrl}
                  id={entity.id}
                  onImagePicked={handleImagePicked}
                  size="small"
                />
              </div>

              {renderAdditionalFields({
                entity,
                updateField,
                errors,
              })}
            </div>
          </div>
          <div className="flex justify-end mt-4 mb-16">
            <Button
              className="uppercase py-6 px-16"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </div>
        </form>
      </div>
    </PageLayout>
  );
}
