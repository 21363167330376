import React from "react";
import {
  useGetAchievementsQuery,
  useGetLevelsQuery,
  useGetMissionsQuery,
} from "@services/api/apiSlice";
import { Requirements } from "../../features/common/Common";
import { TagsInput, CategoryInput } from "../fields/Fields";
import EntitySelector from "./EntitySelector";
import LevelSelector from "./LevelSelector";
import { RequirementLevel } from "@/features/common/Common";
import { useAccount } from "@/features/accounts/useAccount";

interface RequirementPickerProps {
  requirement: Requirements;
  className?: string;
  onChange: (value: Requirements) => void;
}

const RequirementPicker: React.FC<RequirementPickerProps> = ({
  requirement,
  className,
  onChange,
}) => {
  const { account } = useAccount();

  const { data: achievements = [], isLoading: isLoadingAchievements } =
    useGetAchievementsQuery({ account: account?.account || "" });
  const { data: missions = [], isLoading: isLoadingMissions } =
    useGetMissionsQuery({ account: account?.account || "" });
  const { data: levels = [], isLoading: isLoadingLevels } = useGetLevelsQuery({
    account: account?.account || "",
  });

  const handleLevelChange = (level: RequirementLevel | undefined) => {
    if (level) {
      onChange({ ...requirement, level });
    } else {
      // Create a default/empty level when undefined is received
      const defaultLevel: RequirementLevel = {
        id: "",
        ordinal: 0,
      };
      onChange({ ...requirement, level: defaultLevel });
    }
  };
  return (
    <div>
      <h2 className="text-1xl font-bold mb-4">Requirements</h2>

      <div className="grid grid-cols-2 gap-4">
        <div className="col-start-1 row-start-1">
          <CategoryInput
            value={requirement.category}
            onChange={(category) => onChange({ ...requirement, category })}
          />
        </div>
        <div className="col-start-2 row-start-1">
          <TagsInput
            value={requirement.tags}
            onChange={(tags) => onChange({ ...requirement, tags })}
          />
        </div>
        <LevelSelector
          levels={levels}
          selectedLevel={requirement.level}
          isLoading={isLoadingLevels}
          className={className}
          onChange={handleLevelChange}
        />
        <EntitySelector
          entities={missions}
          selectedIds={requirement.missions || []}
          isLoading={isLoadingMissions}
          title="Mission Name"
          listType="Mission"
          className={className}
          onSelectionChange={(ids) =>
            onChange({ ...requirement, missions: ids })
          }
        />

        <EntitySelector
          entities={achievements}
          selectedIds={requirement.achievements || []}
          isLoading={isLoadingAchievements}
          title="Achievement Name"
          listType="Achievement"
          className={className}
          onSelectionChange={(ids) =>
            onChange({ ...requirement, achievements: ids })
          }
        />
      </div>
    </div>
  );
};

export default RequirementPicker;
