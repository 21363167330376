import React, { useState, useEffect, useCallback } from "react";
import { useGetAchievementsQuery } from "@services/api/apiSlice";
import { Rewards } from "@/features/common/Common";
import { PointsInput, CreditsInput } from "../fields/Fields";
import { ListPicker, ListItem } from "@pickers/listPicker";
import { ItemEdit } from "@pickers/itemEdit";
import { useAccount } from "@/features/accounts/useAccount";
import { Achievement } from "@/features/achievements/Achievement";
import AchievementPicker from "@/assets/icons/AchievementPicker.png";

interface RewardPickerProps {
  reward: Rewards;
  className?: string;
  onChange: (value: Rewards) => void;
  achievementId?: string;
}

const RewardsPicker: React.FC<RewardPickerProps> = ({
  reward,
  className,
  onChange,
  achievementId = null,
}) => {
  const { account } = useAccount();
  const { data: achievements = [], isLoading } = useGetAchievementsQuery({
    account: account?.account || "",
  });

  const [selectedAchievements, setSelectedAchievements] = useState<
    Achievement[]
  >([]);

  // Initialize selected achievements from reward
  useEffect(() => {
    if (!isLoading && achievements.length > 0 && reward.achievements) {
      const selected = reward.achievements
        .filter((item) => achievements.some((ach) => ach.id === item.id))
        .map((item) => {
          const achievement = achievements.find((ach) => ach.id === item.id)!;
          return { ...achievement, steps: item.steps };
        });

      setSelectedAchievements(selected);
    }
  }, [isLoading, achievements, reward.achievements]);

  const handleSelect = useCallback(
    (item: ListItem) => {
      if (isLoading) return;

      const achievement = achievements.find((a) => a.id === item.id);
      if (!achievement) return;

      const newSelection = selectedAchievements.some((a) => a.id === item.id)
        ? selectedAchievements.filter((a) => a.id !== item.id)
        : [
            ...selectedAchievements,
            { ...achievement, steps: achievement.steps || 0 },
          ];

      setSelectedAchievements(newSelection);
      onChange({
        ...reward,
        achievements: newSelection.map((item) => ({
          id: item.id,
          steps: item.steps,
        })),
      });
    },
    [isLoading, achievements, selectedAchievements, reward, onChange]
  );

  const handleDelete = useCallback(
    (id: string) => {
      const newSelection = selectedAchievements.filter(
        (item) => item.id !== id
      );
      setSelectedAchievements(newSelection);
      onChange({
        ...reward,
        achievements: newSelection.map((item) => ({
          id: item.id,
          steps: item.steps,
        })),
      });
    },
    [selectedAchievements, reward, onChange]
  );

  const handleStepsChange = useCallback(
    (id: string, steps: number) => {
      const newSelection = selectedAchievements.map((item) =>
        item.id === id ? { ...item, steps } : item
      );
      setSelectedAchievements(newSelection);
      onChange({
        ...reward,
        achievements: newSelection.map((item) => ({
          id: item.id,
          steps: item.steps,
        })),
      });
    },
    [selectedAchievements, reward, onChange]
  );

  const handlePointsChange = useCallback(
    (points: number) => {
      onChange({
        ...reward,
        points,
        achievements: selectedAchievements.map((item) => ({
          id: item.id,
          steps: item.steps,
        })),
      });
    },
    [reward, selectedAchievements, onChange]
  );

  const handleCreditsChange = useCallback(
    (credits: number) => {
      onChange({
        ...reward,
        credits,
        achievements: selectedAchievements.map((item) => ({
          id: item.id,
          steps: item.steps,
        })),
      });
    },
    [reward, selectedAchievements, onChange]
  );

  const achievementListItems = achievements.map((item) => ({
    id: item.id,
    name: item.name,
    disabled:
      selectedAchievements.some((selected) => selected.id === item.id) ||
      item.id === achievementId, // if achiement ID is provided, it should not be selectable
  }));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2 className="text-1xl font-bold mb-4">Rewards</h2>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-start-1 row-start-1">
          <PointsInput value={reward.points} onChange={handlePointsChange} />
        </div>
        <div className="col-start-2 row-start-1">
          <CreditsInput value={reward.credits} onChange={handleCreditsChange} />
        </div>
        <div className="col-start-1 row-start-2">
          <ListPicker
            icon={AchievementPicker}
            items={achievementListItems}
            currentValue={selectedAchievements.at(-1)?.id}
            className={className}
            listType="Achievement"
            onSelect={handleSelect}
            mode="select"
          />
        </div>
        <div className="col-start-2 row-start-2 space-y-4">
          {selectedAchievements.map((item) => (
            <ItemEdit
              key={item.id}
              id={item.id}
              imgUrl={item.imgUrl}
              title="Achievement Name:"
              name={item.name}
              countTitle="Steps Granted:"
              count={item.steps}
              onChange={handleStepsChange}
              onDelete={handleDelete}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RewardsPicker;
