import React, { useEffect, useState } from "react";
import { PageLayout } from "@/components/layout/page-layout";
import { useAccount } from "@/features/accounts/useAccount";
import { defaultAnalytics } from "@/features/analytics/Analytics";
import Loader from "@elements/loader";
import {
  useGetAnalyticsForAccountQuery,
  useGetAggregatedAnalyticsQuery,
} from "@/services/api/apiSlice";
import { usePermissions } from "@/features/permissions/usePermissions";

const getFormattedDate = () => {
  const now = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const formattedMonth = monthNames[now.getMonth()];
  const formattedYear = now.getFullYear().toString().slice(-2);
  return `${formattedMonth}, '${formattedYear}`;
};

interface PanelProps {
  label: string;
  value: string | number | null;
  icon: string;
  variant?: "white" | "yellow";
}

const Panel: React.FC<PanelProps> = ({
  label,
  value,
  icon,
  variant = "white",
}) => {
  const [imageError, setImageError] = useState(false);
  const [isBold, setIsBold] = useState(false);

  useEffect(() => {
    setIsBold(true);
    const timer = setTimeout(() => {
      setIsBold(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [value]);

  const bgColor = variant === "yellow" ? "bg-[#FFF9E6]" : "bg-white";

  return (
    <div className="flex-1 flex flex-col min-h-full">
      <h2 className="text-center mb-1">{label}</h2>
      <div
        className={`${bgColor} border border-[#C4D4FC] rounded-full pl-1 pr-4 flex items-center h-14`}
      >
        {!imageError && (
          <div className="mr-3 h-10 w-10 flex items-center justify-center">
            <img
              src={`/images/icons/${icon}`}
              alt={label}
              className="h-9 w-9"
              onError={() => setImageError(true)}
            />
          </div>
        )}
        <span className={`text-lg ${isBold ? "font-bold" : ""}`}>
          {(value ?? 0).toLocaleString("en-US")}
        </span>
      </div>
    </div>
  );
};

export const PanelAlternate: React.FC<PanelProps> = ({
  label,
  value,
  icon,
}) => {
  return (
    <div className="flex-1 flex flex-col min-h-full">
      <h2 className="text-left mb-1">{label}</h2>
      <div className="bg-white border border-[#C4D4FC] rounded-full pl-2 pr-4 flex items-center h-[5.25rem]">
        <div className="mr-3 h-16 w-16 flex items-center justify-center">
          <img
            src={`/images/icons/${icon}`}
            alt={label}
            className="h-15 w-15"
          />
        </div>
        <span className="text-2xl font-bold">
          {(value ?? 0).toLocaleString("en-US")}
        </span>
      </div>
    </div>
  );
};

const AnalyticsPage: React.FC = () => {
  const { account } = useAccount();
  const { canReadAll } = usePermissions();

  const {
    data: accountAnalytics = defaultAnalytics,
    error: accountError,
    isLoading: isAccountLoading,
    refetch: refetchAccount,
  } = useGetAnalyticsForAccountQuery(account?.account || "", {
    skip: !account,
  });

  const {
    data: aggregatedAnalytics = defaultAnalytics,
    error: aggregatedError,
    isLoading: isAggregatedLoading,
    refetch: refetchAggregated,
  } = useGetAggregatedAnalyticsQuery("", {
    skip: !canReadAll(),
  });

  useEffect(() => {
    // Determine which refetch function to use based on account existence
    const refetchFunction = account ? refetchAccount : refetchAggregated;

    const interval = setInterval(() => {
      if (account || canReadAll()) {
        refetchFunction();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [account, refetchAccount, refetchAggregated, canReadAll]);

  const isLoading = isAccountLoading || isAggregatedLoading;
  const error = accountError || aggregatedError;
  const analytics = account ? accountAnalytics : aggregatedAnalytics;

  if (isLoading) {
    return (
      <PageLayout>
        <Loader />
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <div>{error.toString()}</div>
      </PageLayout>
    );
  }

  if (!account && !canReadAll()) {
    return (
      <PageLayout>
        <span>Please select an account</span>
      </PageLayout>
    );
  }

  const content = (
    <section className="analytics-page flex flex-col gap-6">
      {/* Top row: Account and Payment Plans */}
      <div className="flex gap-6">
        <div className="bg-neutral-100 rounded-3xl flex-1 p-6 flex flex-col min-h-[250px]">
          <div>
            <h1>{account ? account.name : "All Accounts"}</h1>
            <p className="text-2xl text-gray-500 font-bold">
              {getFormattedDate()}
            </p>
          </div>
          <div className="mt-auto">
            <PanelAlternate
              label="Active Players"
              value={analytics.activePlayers}
              icon="active_users.png"
            />
          </div>
        </div>
        <div className="bg-neutral-100 rounded-3xl flex-1 p-6 flex flex-col">
          <div>
            <h2>Payment Plan</h2>
            <p>{analytics.debug}</p>
          </div>
          <div className="mt-auto">
            <p className="capitalize">{analytics.debug}</p>
            <p className="bg-white rounded-full p-4">{analytics.debug}</p>
          </div>
        </div>
      </div>

      {/* Second row: Main metrics */}
      <div className="flex gap-6">
        <Panel
          label="Missions Completed"
          value={analytics.missions}
          icon="missions.png"
          variant="yellow"
        />
        <Panel
          label="Achievements Granted"
          value={analytics.achievements}
          icon="achievements.png"
          variant="yellow"
        />
        <Panel
          label="Level-Ups"
          value={analytics.levelUps}
          icon="levels.png"
          variant="yellow"
        />
      </div>

      {/* Grid layout for remaining panels */}
      <div className="bg-neutral-100 rounded-3xl p-6">
        <div className="grid grid-cols-4 gap-6">
          <Panel
            label="Players Registered"
            value={analytics.players}
            icon="users.png"
          />
          <Panel
            label="Missions Started"
            value={analytics.startedMissions}
            icon="missions_started.png"
          />
          <Panel
            label="API Calls Processed"
            value={analytics.apiCalls}
            icon="api_calls.png"
          />
          <Panel
            label="Achievements Unlocked"
            value={analytics.unlockedAchievements}
            icon="achievements_unlocked.png"
          />
          <Panel
            label="Points Awarded"
            value={analytics.points}
            icon="points.png"
          />
          <Panel
            label="Credits Earned"
            value={analytics.credits}
            icon="credits.png"
          />
          <Panel
            label="Prizes Claimed"
            value={analytics.prizes}
            icon="monthly_price.png"
          />
          <Panel
            label="Raffles Entered"
            value={analytics.prizeDraws}
            icon="raffles.png"
          />
          <Panel
            label="Mystery Boxes Opened"
            value={analytics.mysteryBoxes}
            icon="mysterybox.png"
          />
          <Panel
            label="Quizzes Completed"
            value={analytics.quizzes}
            icon="quizzes.png"
          />
          <Panel
            label="Surveys Taken"
            value={analytics.surveys}
            icon="surveys.png"
          />
          <Panel
            label="Streaks Active"
            value={analytics.streaksStarted}
            icon="streaks_active.png"
          />
          <Panel
            label="Streaks Completed"
            value={analytics.streaksCompleted}
            icon="streaks.png"
          />
        </div>
      </div>
    </section>
  );

  return <PageLayout>{content}</PageLayout>;
};

export default AnalyticsPage;
