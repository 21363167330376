import {
  ElementBase,
  Language,
  Rewards,
  defaultRewards,
  defaultElementBase,
} from "@features/common/Common";

export interface Event extends ElementBase {
  limitCount: boolean;
  countLimit: number;
  reward: Rewards;
  languages: Language[];
}

// Robert: when creating a new empty event, use the defaultEvent
// it will automatically populate fields with proper default values
export const defaultEvent: Event = {
  ...defaultElementBase,
  limitCount: false,
  countLimit: 0,
  reward: defaultRewards,
  languages: [],
};
