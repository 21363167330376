import React from "react";
import { Label } from "@/components/ui/label";

const imgDelete = "/images/delete.png";

interface ItemPickerProps {
  title: string;
  id: string;
  name: string;
  imgUrl: string;
  onDelete: (id: string) => void;
}

const ItemPicker: React.FC<ItemPickerProps> = ({
  title,
  id,
  name,
  imgUrl,
  onDelete,
}) => {
  return (
    <div className="flex items-center p-2 border rounded-2xl shadow-sm bg-white border-primary-200 focus:border-indigo-500 hover:border-indigo-500 relative">
      <img
        src={imgUrl}
        alt={name}
        className="w-12 h-12 object-cover rounded-2xl mr-2"
      />
      <div className="flex-grow flex flex-col justify-center">
        <Label htmlFor={`title-${id}`} className="text-gray-600 mb-1 text-sm">
          {title}
        </Label>
        <Label htmlFor={`name-${id}`} className="text-base font-bold">
          {name}
        </Label>
      </div>
      <div className="absolute top-3 right-3 flex space-x-2">
        <img
          src={imgDelete}
          onClick={() => onDelete(id)}
          className="w-4 h-4 cursor-pointer"
          alt="Delete"
        ></img>
      </div>
    </div>
  );
};

ItemPicker.displayName = "ItemPicker";

export default ItemPicker;
