import React, { useState, useCallback, ChangeEvent } from "react";

import { Input } from "@components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import FieldHelp from "@/components/elements/fieldHelp";

const imgPlus = "/images/plus.png";
const imgMinus = "/images/minus.png";

// Common interface for input props
interface StringInputProps {
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  entity_name?: string;
}

interface InputWrapperProps {
  name: string;
  label: string;
  helpText?: string;
  required?: boolean;
  error?: string;
  children: React.ReactNode;
}

// InputWrapper component
// "block text-sm font-medium text-gray-700"
const InputWrapper: React.FC<InputWrapperProps> = ({
  name,
  label,
  helpText,
  required,
  error,
  children,
}) => (
  <div>
    <div className="flex justify-between items-center m-2">
      <Label className="text-sm" htmlFor={name}>
        {label && (required ? label : label)}
      </Label>
      <FieldHelp helpText={helpText} />
    </div>
    {children}
    {error && <span style={{ color: "red", fontSize: "0.8rem" }}>{error}</span>}
  </div>
);

// IdInput component
export const IdInput: React.FC<StringInputProps> = ({
  entity_name,
  value,
  onChange,
  required = false,
  error,
  placeholder,
  disabled = false,
}) => (
  <InputWrapper
    name="id"
    label={entity_name ? entity_name + " ID" : "ID"}
    required={required}
    helpText="Unique identifier of the element that will be used throughout the system and cannot be changed."
    error={error}
  >
    <Input
      className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full -sshadowm sm:text-sm border-primary-200 bg-white rounded-2xl"
      type="text"
      id="id"
      name="id"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      placeholder={placeholder}
      disabled={disabled}
    />
  </InputWrapper>
);

// NameInput component
export const NameInput: React.FC<StringInputProps> = ({
  entity_name,
  value,
  onChange,
  required = false,
  error,
  placeholder,
}) => (
  <InputWrapper
    name="name"
    label={entity_name ? entity_name + " Name" : "Name"}
    required={required}
    helpText={"Name of the element that you can use in the front-end."}
    error={error}
  >
    <Input
      className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-primary-200 bg-white rounded-2xl"
      type="text"
      id="name"
      name="name"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      placeholder={placeholder}
    />
  </InputWrapper>
);

// DescriptionInput component
// className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-primary-200 bg-white rounded-2xl"
export const DescriptionInput: React.FC<StringInputProps> = ({
  value,
  onChange,
  required = false,
  error,
  placeholder,
}) => (
  <InputWrapper
    name="description"
    label="Description"
    required={required}
    helpText="Description of the element that you can use in the front-end."
    error={error}
  >
    <Textarea
      className="w-full h-full min-h-[100px] md:min-h-[200px] p-4 border-primary-200 focus:ring-0 focus:outline-none focus:bg-white focus:border-primary-500 bg-white resize-none"
      id="description"
      name="description"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      placeholder={placeholder}
    />
  </InputWrapper>
);

// CategoryInput component
export const CategoryInput: React.FC<StringInputProps> = ({
  value,
  onChange,
  required = false,
  error,
}) => (
  <InputWrapper
    name="category"
    label="Category"
    required={required}
    helpText="Use a category (single) to personalize the experience for your players ."
    error={error}
  >
    <Input
      className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-primary-200 bg-white rounded-2xl"
      type="text"
      id="category"
      name="category"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
    />
  </InputWrapper>
);

// TagsInput component
interface TagsStringInputProps {
  value: string[];
  onChange: (value: string[]) => void;
  required?: boolean;
  error?: string;
  placeholder?: string;
}

export const TagsInput: React.FC<TagsStringInputProps> = ({
  value,
  onChange,
  required = false,
  error,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tags = e.target.value.split(",").map((tag) => tag.trim());
    onChange(tags);
  };

  return (
    <InputWrapper
      name="tags"
      label="Tags"
      required={required}
      helpText="Use tags (separated by commas) to personalize the experience for your players."
      error={error}
    >
      <Input
        className="mt-1 focus:ring-primary-500 focus:border-primary-500 block w-full shadow-sm sm:text-sm border-primary-200 bg-white rounded-2xl"
        type="text"
        id="tags"
        name="tags"
        value={Array.isArray(value) ? value.join(",") : value}
        onChange={handleChange}
        required={required}
      />
    </InputWrapper>
  );
};

interface NumericInputProps {
  value: number;
  onChange: (value: number) => void;
  required?: boolean;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
}

// Base NumericInput component (internal use only)
interface BaseNumericInputProps extends NumericInputProps {
  name: string;
  helpText?: string;
  min?: number;
  max?: number;
}

const BaseNumericInput: React.FC<BaseNumericInputProps> = ({
  value,
  onChange,
  name,
  label = "Default value",
  error,
  placeholder,
  helpText = "Provide numeric value.",
  required = false,
  disabled = false,
  min,
  max,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
    onChange(newValue);
  };

  return (
    <InputWrapper
      name={name}
      label={label}
      required={required}
      helpText={helpText}
      error={error}
    >
      <Input
        type="number"
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 hover:border-indigo-500 block w-full shadow-sm sm:text-sm border-primary-200 rounded-2xl"
        id={name}
        name={name}
        value={value || ""}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        min={min}
        max={max}
      />
    </InputWrapper>
  );
};

// RestrictedCountInput component
export const RestrictedCountInput: React.FC<NumericInputProps> = ({
  value,
  onChange,
  error,
  disabled = false,
  required = false,
  label = "Restricted Count",
}) => (
  <BaseNumericInput
    name="restrictedCount"
    label={label}
    helpText="How many times your player can complete the element within the refresh period (if specified)."
    value={value}
    onChange={onChange}
    error={error}
    placeholder="0"
    disabled={disabled}
    required={required}
    min={0}
  />
);

// PointsInput component
export const PointsInput: React.FC<NumericInputProps> = ({
  value,
  onChange,
  required = false,
  error,
  disabled = false,
}) => (
  <BaseNumericInput
    name="points"
    label="Points"
    value={value}
    onChange={onChange}
    error={error}
    placeholder="0"
    helpText="How many experience points your player will earn for completing this element."
    disabled={disabled}
    required={required}
    min={0}
  />
);

// CreditsInput component
export const CreditsInput: React.FC<NumericInputProps> = ({
  value,
  onChange,
  required = false,
  error,
  disabled = false,
}) => (
  <BaseNumericInput
    name="credits"
    label={"Credits"}
    value={value}
    onChange={onChange}
    error={error}
    placeholder="0"
    helpText="How many credits your player will be awarded for completing this element."
    disabled={disabled}
    required={required}
    min={0}
  />
);

// PriorityInput component
export const PriorityInput: React.FC<NumericInputProps> = ({
  value,
  onChange,
  required = false,
  error,
  disabled = false,
}) => (
  <BaseNumericInput
    name="priority"
    label="Priority"
    value={value}
    onChange={onChange}
    error={error}
    placeholder="0"
    helpText="Priority for the mission."
    disabled={disabled}
    required={required}
    min={0}
  />
);

export const StepInput: React.FC<NumericInputProps> = ({
  value,
  onChange,
  required = false,
  error,
  disabled = false,
}) => (
  <BaseNumericInput
    name="steps"
    label="Steps Required"
    value={value}
    onChange={onChange}
    error={error}
    placeholder="0"
    helpText="Steps required to complete the achievement"
    disabled={disabled}
    required={required}
    min={0}
  />
);

export const OrdinalInput: React.FC<NumericInputProps> = ({
  value,
  onChange,
  required = true,
  error,
  disabled = false,
}) => (
  <BaseNumericInput
    name="ordinal"
    label="Ordinal (Starting at 0)"
    value={value}
    onChange={onChange}
    error={error}
    placeholder="0"
    helpText="Unique ordinal idendifying the level"
    disabled={disabled}
    required={required}
    min={0}
  />
);
// Boolean type of input fields
interface BooleanInputProps {
  value: boolean;
  onChange: (value: boolean) => void;
  error?: string;
  label?: string;
  name: string;
}
// className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 hover:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl"
// className="flex items-center space-x-2">
const BooleanInput: React.FC<BooleanInputProps> = ({
  value,
  onChange,
  name,
  label,
}) => (
  <div className="flex items-center space-x-2">
    <Switch
      id={name}
      checked={value}
      onCheckedChange={(value) => onChange(value)}
    />
    <Label htmlFor="toggle-switch">{label}</Label>
  </div>
);

export const IsAvailableInput: React.FC<
  Omit<BooleanInputProps, "label" | "name">
> = ({ value, ...props }) => (
  <BooleanInput
    {...props}
    value={value}
    label={value ? "Available" : "Disable"}
    name="isAvailable"
  />
);

export const IsLimitInput: React.FC<
  Omit<BooleanInputProps, "label" | "name">
> = ({ value, ...props }) => (
  <BooleanInput
    {...props}
    value={value}
    label={value ? "Disable Restriction" : "Enable Restriction"}
    name="isLimit"
  />
);

interface CustomNumberInputProps {
  id: string;
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
}

export const CustomNumberInput: React.FC<CustomNumberInputProps> = ({
  id,
  value,
  onChange,
  min = 0,
  max = Infinity,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useState(value.toString());

  const updateValue = useCallback(
    (newValue: number) => {
      if (newValue >= min && newValue <= max && !disabled) {
        onChange(newValue);
        setInputValue(newValue.toString());
      }
    },
    [min, max, onChange, disabled]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;

      const newValue = e.target.value;
      if (newValue === "" || /^\d+$/.test(newValue)) {
        setInputValue(newValue);
        const numericValue = parseInt(newValue, 10);
        if (!isNaN(numericValue)) {
          updateValue(numericValue);
        }
      }
    },
    [updateValue, disabled]
  );

  const handleIncrement = useCallback(() => {
    if (!disabled) {
      updateValue(value + 1);
    }
  }, [value, updateValue, disabled]);

  const handleDecrement = useCallback(() => {
    if (!disabled) {
      updateValue(value - 1);
    }
  }, [value, updateValue, disabled]);

  const handleBlur = useCallback(() => {
    if (inputValue === "") {
      setInputValue(value.toString());
    }
  }, [inputValue, value]);

  return (
    <div className="flex items-center">
      <Input
        id={id}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        className="w-16 text-center"
        disabled={disabled}
      />
      <div className="flex ml-2 space-x-1">
        <img
          src={imgMinus}
          onClick={handleDecrement}
          className="w-6 h-6 cursor-pointer"
          alt="Decrement"
        ></img>
        <img
          src={imgPlus}
          onClick={handleIncrement}
          className="w-6 h-6 cursor-pointer"
          alt="Increment"
        ></img>
      </div>
    </div>
  );
};
