import { useContext } from "react";
import { AccountContext } from "./AccountProvider";
import { AccountContextType } from "./Account";

export const useAccount = (): AccountContextType => {
  const context = useContext(AccountContext);

  if (context === undefined) {
    throw new Error("useAccount must be used within an AccountProvider");
  }


  return context;
};
