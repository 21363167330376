import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { Link as BaseLink, To } from 'react-router-dom';
import { cn } from "@/lib/utils";

import Arrow from "@assets/arrow_left.svg"

const linkVariants = cva(
  "flex m-4 gap-2 items-center text-black text-lg font-bold"
)

export interface LinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
  VariantProps<typeof linkVariants> {
    to: To
    variant?: "withIcon" | "default"
  }

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, variant, ...props }) => {
    const ArrowIcon = <img src={Arrow} />
    const Comp = <BaseLink
      {...props}
      className={cn(linkVariants(), className)}
    >
      {variant === "withIcon" && ArrowIcon}
      <h4>
        {props.children}
      </h4>
    </BaseLink>;
    return Comp;
  }
)

Link.displayName = "Link"

export { Link }
