import { createContext, useState, ReactNode, useCallback } from "react";

import { User } from "./User";

const APP_METADATA = "https://api.gamelayer.co/app_metadata";

interface UserContextType {
  user: User | null;
  setUserFromToken: (token: string) => void;
  clearUser: () => void;
  isLoading: boolean;
}

interface JWTPayload {
  sub: string;
  permissions: string[];
  scope: string;
  [APP_METADATA]: {
    isAuthorized: boolean;
    accounts: string[];
  };
}

/**
 * @description Parse JWT and returns a JSON object
 * @param {*} token
 * @returns JSON object or null if parsing failed
 */
const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const UserContext = createContext<UserContextType | undefined>(
  undefined
);

export function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading] = useState(false);

  const setUserFromToken = useCallback((token: string) => {
    try {
      const payload = parseJwt(token) as JWTPayload;
      const appMetadata = payload[APP_METADATA];
      const user = {
        id: payload.sub,
        permissions: payload.permissions || [],
        accounts: appMetadata?.accounts || [],
        isAuthorized: appMetadata?.isAuthorized || false,
        scope: payload.scope,
      };
      setUser(user);
    } catch (error) {
      console.error("Error decoding token:", error);
      setUser(null);
    }
  }, []);

  const clearUser = useCallback(() => {
    setUser(null);
  }, []);

  return (
    <UserContext.Provider
      value={{ user, setUserFromToken, clearUser, isLoading }}
    >
      {children}
    </UserContext.Provider>
  );
}
