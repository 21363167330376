// EventPage.tsx
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetEventsQuery,
  useAddEventMutation,
  useUpdateEventMutation,
} from "@services/api/apiSlice";
import { useAccount } from "@/features/accounts/useAccount";
import { BaseEntityForm } from "../../components/forms/BaseEntityForm";
import {
  CategoryInput,
  TagsInput,
  RestrictedCountInput,
  IsLimitInput,
} from "@fields/Fields";
import RewardsPicker from "@pickers/rewardsPicker";
import { Event, defaultEvent } from "./Event";

const EventPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { account } = useAccount();
  const navigate = useNavigate();

  const { data: events = [], isLoading } = useGetEventsQuery({
    account: account?.account || "",
  });
  const [addNewEvent] = useAddEventMutation();
  const [updateEvent] = useUpdateEventMutation();

  const isNew = id === "new";

  useEffect(() => {
    if (!isNew) {
      const eventToEdit = events.find((event) => event.id === id);
      if (
        !account ||
        (eventToEdit && eventToEdit.account !== account.account)
      ) {
        navigate("/events");
      }
    }
  }, [events, id, navigate, account, isNew]);

  const eventToEdit = events.find((event) => event.id === id);
  const currentEvent = isNew
    ? { ...defaultEvent, account: account?.account || "" }
    : eventToEdit || defaultEvent;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseEntityForm<Event>
      entityName="Event"
      entityPath="events"
      entity={currentEvent}
      isNew={isNew}
      defaultEntity={defaultEvent}
      addEntity={(entity) => addNewEvent(entity).unwrap()}
      updateEntity={(entity) => updateEvent(entity).unwrap()}
      renderAdditionalFields={({ entity, updateField }) => (
        <>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-4 gap-4">
            <CategoryInput
              value={entity.category}
              onChange={(value) => updateField("category", value)}
            />
            <TagsInput
              value={entity.tags}
              onChange={(value) => updateField("tags", value)}
            />
            <div className="flex flex-col justify-end h-full">
              <RestrictedCountInput
                value={entity.countLimit}
                onChange={(value) => updateField("countLimit", value)}
                placeholder="Limit number of completions"
                disabled={!entity.limitCount}
              />
            </div>
            <div className="flex items-stretch h-9 mt-auto">
              <IsLimitInput
                value={entity.limitCount}
                onChange={(value) => updateField("limitCount", value)}
              />
            </div>
          </div>
          <div className="col-span-2 my-4 border border-primary-200 border-t-0 border-r-0 border-l-0" />
          <div className="col-span-2">
            <RewardsPicker
              reward={entity.reward}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(reward) => updateField("reward", reward)}
            />
          </div>
        </>
      )}
      additionalValidation={(entity, errors) => {
        // Add any Event-specific validation here
        if (entity.limitCount && !entity.countLimit) {
          errors.countLimit = "Count limit is required when limit is enabled";
        }
      }}
    />
  );
};

export default EventPage;
