import React from "react";
import { LoginButton } from "@buttons/loginButton";
import { SignUpButton } from "@buttons/signUpButton";
import Logo from "@elements/logo";

export const HomePage: React.FC = () => (
  <div className="flex container w-screen">
    <aside className="sidebar flex w-full max-w-64 h-dvh min-h-100 bg-blue-700 place-items-center text-white">
      <blockquote className="p-4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse imperdiet congue feugiat. Suspendisse pharetra quam eu scelerisque ultricies. Mauris ante justo, ultricies in pretium eget, dignissim eget diam. 
      </blockquote>
    </aside>
    <div className="main w-full flex h-screen flex justify-center items-center">
      <div className="flex flex-col grow gap-6 mx-auto max-w-xl text-center">
        <h1 className="flex gap-2 justify-center items-center">
          <Logo src="" alt="gameLayer" />
          <span className="text-2xl">
            GameLayer
          </span>
        </h1>
        <div>Welcome to GameLayer that rewards your physical activity with donations to good causes and sustainable initiatives</div>
        <div className="flex gap-2 justify-center items-center">
          <LoginButton />
          <SignUpButton />
        </div>
      </div>
    </div>
  </div>
);
