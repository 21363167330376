import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { useUser } from "@features/user/useUser";

import { PageLoader } from "@layout/page-loader";
import { AuthenticationGuard } from "@layout/authentication-guard";
import { CallbackPage } from "@pages/callback-page";
import { HomePage } from "@pages/home-page";
import { NotFoundPage } from "@pages/not-found-page";
import { ProfilePage } from "@pages/profile-page";
import { ProtectedPage } from "@pages/protected-page";
import { PublicPage } from "@pages/public-page";
import AccountsPage from "@features/accounts/accountsPage";
import EventsPage from "@features/events/eventsPage";
import EventPage from "@features/events/eventPage";
import MissionPage from "@features/missions/missionPage";
import MissionsPage from "@features/missions/missionsPage";
import AchievementPage from "@/features/achievements/achievementPage";
import AchievementsPage from "@/features/achievements/achievementsPage";
import StreakPage from "@features/streaks/streakPage";
import StreaksPage from "@features/streaks/streaksPage";
import { setAuthToken } from "@services/api/apiSlice";
import LevelsPage from "@features/levels/levelsPage";
import LevelPage from "@/features/levels/levelPage";
import AnalyticsPage from "@/pages/analytics-page";

export const App: React.FC = () => {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const { setUserFromToken } = useUser();
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        // Get the token
        const token = await getAccessTokenSilently();

        // Set the token for API calls
        setAuthToken(() => getAccessTokenSilently());

        // Set user context from token
        setUserFromToken(token);
      } catch (error) {
        console.error("Error initializing auth:", error);
      }
    };

    if (!isLoading) {
      initializeAuth();
    }
  }, [getAccessTokenSilently, isLoading, setUserFromToken]);

  /*
  useEffect(() => {
    setAuthToken(() => getAccessTokenSilently());
  }, [getAccessTokenSilently]);
  */

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoading) {
        setShowLoader(false);
      }
    }, 1500); // Adjust the delay (in milliseconds) as needed

    return () => clearTimeout(timer);
  }, [isLoading]);

  if (isLoading || showLoader) {
    return <PageLoader />;
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/home"
        element={<AuthenticationGuard component={AnalyticsPage} />}
      />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />
      <Route
        path="/accounts"
        element={<AuthenticationGuard component={AccountsPage} />}
      />
      <Route
        path="/levels"
        element={<AuthenticationGuard component={LevelsPage} />}
      />
      <Route
        path="/levels/:id"
        element={<AuthenticationGuard component={LevelPage} />}
      />
      <Route
        path="/events"
        element={<AuthenticationGuard component={EventsPage} />}
      />
      <Route
        path="/events/:id"
        element={<AuthenticationGuard component={EventPage} />}
      />
      <Route
        path="/missions"
        element={<AuthenticationGuard component={MissionsPage} />}
      />
      <Route
        path="/missions/:id"
        element={<AuthenticationGuard component={MissionPage} />}
      />
      <Route
        path="/streaks"
        element={<AuthenticationGuard component={StreaksPage} />}
      />
      <Route
        path="/streaks/:id"
        element={<AuthenticationGuard component={StreakPage} />}
      />
      <Route
        path="/achievements"
        element={<AuthenticationGuard component={AchievementsPage} />}
      />
      <Route
        path="/achievements/:id"
        element={<AuthenticationGuard component={AchievementPage} />}
      />
      <Route path="/public" element={<PublicPage />} />
      <Route
        path="/protected"
        element={<AuthenticationGuard component={ProtectedPage} />}
      />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
